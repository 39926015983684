import * as React from 'react';

import { useLanguageContext } from "../context/LanguageContext";

import Avatar from '@mui/joy/Avatar';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

function renderValue(option) {
  if (!option) {
    return null;
  }

  return (
    <React.Fragment>
      <ListItemDecorator>
        <Avatar size="sm" src={`/img/lng/${option.value}.png`} />
      </ListItemDecorator>
    </React.Fragment>
  );
}


const LanguageSelect = () => {
  const { languages, onClickLanguageChange, i18n } = useLanguageContext();

  return (
    <Select
      defaultValue={i18n.language}
      slotProps={{
        listbox: {
          sx: {
            '--ListItemDecorator-size': '44px',
          },
          placement: 'bottom-end'
        },
      }}
      sx={{
        '--ListItemDecorator-size': '44px',

      }}
      renderValue={renderValue}
      onChange={onClickLanguageChange}
    >
      {Object.keys(languages).map((lng) => (
        <React.Fragment key={languages[lng].nativeName}>
          <Option value={lng} label={languages[lng].nativeName}>
            <ListItemDecorator>
              <Avatar size="sm" src={`/img/lng/${lng}.png`} />
            </ListItemDecorator>
            {languages[lng].nativeName}
          </Option>
        </React.Fragment>
      ))}
    </Select>
  );
};

export default LanguageSelect;