/**
 * Translate description: Add button component
 */

import { Link as ReactLink } from 'react-router-dom';

import Button from '@mui/joy/Button';

import AddCircleOutlineRounded from '@mui/icons-material/AddCircleOutlineRounded';

export function AddButton({ to, label }) {
  return (
    <ReactLink to={to} textDecoration="none">
      <Button
        color="primary"
        startDecorator={<AddCircleOutlineRounded />}
        size="sm"
      >
        {label}
      </Button>
    </ReactLink>
  );
}