/**
 * Translate description: Filter component for basic publications
 */

import { Fragment, useState, useContext, useEffect } from 'react';

import { useLanguageContext } from "../context/LanguageContext";
import AppContext from '../context/AppContext';

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Sheet from '@mui/joy/Sheet';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';

import DebounceInput from './DebouncedInput';
import SelectSourceLang from './SelectSourceLang';

const renderFilters = ({
  setPaginator,
  filter,
  setFilter
}) => {
  const { t } = useLanguageContext();

  const { currentLangId } = useContext(AppContext);

  return (
    <Fragment>
      <FormControl size="sm">
        <FormLabel>{t('Source language')}</FormLabel>
        <SelectSourceLang />
      </FormControl>
      <FormControl size="sm">
        <FormLabel>{t('Translate')}</FormLabel>
        <Select
          size="sm"
          placeholder={t('Filter by translate')}
          slotProps={{
            button: { sx: { whiteSpace: 'nowrap' } },
            listbox: { placement: 'bottom-end' },
          }}
          onChange={(e, v) => {
            setFilter({ ...filter, lang: { lang_id: currentLangId.toString(), filter_type: v } });
            setPaginator({ currentPage: 1 });
          }}
        >
          <Option value="all">{t('All')}</Option>
          <Option value="only">{t('Translated')}</Option>
          <Option value="not">{t('Not translated')}</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>{t('Status')}</FormLabel>
        <Select
          size="sm"
          placeholder={t('Filter by status')}
          slotProps={{
            button: { sx: { whiteSpace: 'nowrap' } },
            listbox: { placement: 'bottom-end' },
          }}
          onChange={(e, v) => {
            let statuses = {
              "all": [1, 2],
              "draft": [1],
              "published": [2]
            }
            setFilter({ ...filter, status_in: statuses[v] });
            setPaginator({ currentPage: 1 });
          }}
          defaultValue=""
        >
          <Option value={"all"}>{t('All')}</Option>
          <Option value={"draft"}>{t('Draft')}</Option>
          <Option value={"published"}>{t('Published')}</Option>
        </Select>
      </FormControl>
    </Fragment>
  )
};

export default function Filters() {
  const { t } = useLanguageContext();
  const [open, setOpen] = useState(false);
  const { setPaginator } = useContext(AppContext);
  const { filter, setFilter } = useContext(AppContext);

  const handleDebounce = (e) => {
    setFilter({ ...filter, search: e.target.value });
    setPaginator({ currentPage: 1 });
  };

  return (
    <>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: 'flex', sm: 'none' },
          my: 1,
          gap: 1,
        }}
      >
        <DebounceInput
          size="sm"
          placeholder={t('Search')}
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
          debounceTimeout={500}
          handleDebounce={handleDebounce}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              {t('Filters')}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters({ setPaginator, filter, setFilter })}
              <Button color="primary" onClick={() => setOpen(false)}>
                {t('Submit')}
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'none', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>{t('Search')}</FormLabel>
          <DebounceInput size="sm"
            placeholder={t('Search')}
            startDecorator={<SearchIcon />}
            debounceTimeout={500}
            handleDebounce={handleDebounce}
          />
        </FormControl>
        {renderFilters({ setPaginator, filter, setFilter })}
      </Box>
    </>
  )
}