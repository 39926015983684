/**
 * Translate description: Form control field
 */

import { Box, Tooltip } from '@mui/joy';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { memo, useContext } from "react";


function elementSettingsEqual(prev, next) {
    for (const key in prev) {
        if (!(key in next)) return false;
        if (key === "elements") continue;
        if (prev[key] !== next[key]) return false;
    }
    return true;
}

function propsEqual(prev, next) {
    for (const key in prev) {
        if (key === "elementSettings") {
            if (!elementSettingsEqual(prev.elementSettings, next.elementSettings)) {
                return false;
            }
        } else if (!(key in next) || prev[key] !== next[key]) {
            return false;
        }
    }
    return true;
}

// WARNING !!!
// prop "elements" of elementSettings is not allowed to be changed
const emptyObject = {}
export function makeField(context) {
    function Field({ name, label, placeholder, value, setter, Element, elementSettings, rightTitleDecorator, tooltip, required }) {
        const { errors, setErrors } = useContext(context)
        Element = Element || Input;
        const hasError = (fieldName) => fieldName in errors;

        return <FormControl error={hasError(name)}>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Tooltip title={tooltip}>
                    <FormLabel sx={{ userSelect: "auto" }}>{label}{required ? '*' : ''}</FormLabel>
                </Tooltip>
                {rightTitleDecorator}
            </Box>
            <Element placeholder={placeholder || label} value={value} onChange={(e, v) => { setter(v ?? e.target.value); setErrors(emptyObject); }} {...elementSettings} />
            <FormHelperText>{errors[name]?.message}</FormHelperText>
        </FormControl>
    }
    return memo(Field, (prev, next) => { return propsEqual(prev, next); })
}
